<span
  class="sort"
  (click)="updateSortDirection()"
>
  <span>
    <ng-content></ng-content>
  </span>
  <span>
    <em
      class="a-icon boschicon-bosch-ic-arrow-{{ iconClass }}"
      [title]="iconTitlePropKey | translate"
    ></em>
  </span>
</span>
