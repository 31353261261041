<div class="a-text-field">
  <input
    [formControl]="searchInput"
    type="search"
    id="search-input-1"
    name="search input"
    [placeholder]="
      'interface.listSearchPlaceholder'
        | translate: { minCharacters: minCharacters }
    "
  />
</div>
