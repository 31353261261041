<nav
  elTestId="7MLClErXfrTkTzNE1LWcS"
  class="o-context-menu"
  aria-label="User context menu for logout and switching"
  aria-hidden="false"
>
  <div
    elTestId="4Z2EPhgvIGfUKurkALIfy"
    class="m-popover -without-arrow -show"
  >
    <div
      elTestId="7YWyqSKQ0C_Gne7iDiJea"
      class="a-box -floating"
    >
      <div
        elTestId="xPGv6yG89vBfNUuW6kfE0"
        class="m-popover__content"
      >
        <ul
          elTestId="y05KwYtBfQDg25w7ltTEZ"
          class="o-context-menu__menuItems"
          role="menubar"
        >
          <li
            elTestId="aNB9BB2-ZLGwcU5p7VDPV"
            *ngIf="currentLoginType === LoginType.SingleKeyId"
            class="a-menu-item single-key-login"
            role="none"
            (click)="hideMenu()"
          >
            Logged in with
            <img
              elTestId="8B1NWXKo8e6E0QcY1DhPX"
              src="../../../../../assets/images/skid_logo_key_desc.svg"
              class="logo-style"
              title="Open"
              alt="Single Key Id Icon"
              (click)="open()"
            />
            <div
              elTestId="9S_yde6Xot4jG8CimZnMg"
              class="horizontal-line"
            ></div>
          </li>
          <li
            elTestId="tCtrHTx22ErFwY7EDyo89"
            class="a-menu-item"
            role="none"
          >
            <a
              elTestId="pIBAGC71AOIyibG_H-ByP"
              role="menuitem"
              class="a-menu-item__link"
              aria-disabled="false"
              aria-controls="group-id 1"
            >
              <em
                elTestId="oBMqd3SdXtWVGFuc8hRCw"
                class="a-icon boschicon-bosch-ic-list-view"
                title="Subscriptions icon"
              ></em>
              <span
                elTestId="07t-5M2CZbJN0UWt83Zxo"
                class="a-menu-item__label"
                >{{ 'interface.subscriptions' | translate }}</span
              >
              <em
                elTestId="3bCh6LS5jOKkaRiUAQEM7"
                class="a-icon boschicon-bosch-ic-forward-right"
                title="Expand subscription list icon"
              ></em>
            </a>
            <ul
              elTestId="a1TYpuT3jP-_2Qe1rQx1-"
              class="o-context-menu__menuSubitems--beside -floating"
              role="menu"
              id="group-id 3"
              (click)="hideMenu()"
            >
              <li
                elTestId="H0cN1Yy9x-kkCkmSeN_pv"
                *ngFor="
                  let item of userSubscriptionService.userSubscriptions$ | async
                "
                class="a-menu-item"
                role="none"
                (click)="changeMenu(item)"
              >
                <a
                  elTestId="FV5uwK35cNdrnE7f82dAh"
                  role="menuitem"
                  class="a-menu-item__link"
                  aria-controls="group-id undefined"
                >
                  <span
                    elTestId="I2EL7lv0IlSuLhjqQH_vv"
                    class="a-menu-item__label"
                    >{{ item.name }}
                    <div
                      elTestId="dBIxAl8eK4fIgYHdxop6v"
                      *ngIf="
                        item.id ===
                        (userSubscriptionService.activeSubscriptionId$ | async)
                      "
                      class="a-badge"
                      tabindex="0"
                      role="status"
                      aria-live="off"
                    >
                      {{ 'interface.current' | translate }}
                    </div></span
                  >
                </a>
              </li>

              <hr
                elTestId="60Log77yzmuPgbpWdMvbq"
                class="a-divider"
              />
              <li
                elTestId="edVaxpM3m7OFhssPFilXD"
                class="a-menu-item"
                role="none"
              >
                <a
                  elTestId="yOKupRaqhAmR7PGaFoKR4"
                  (click)="showRedeemDialog()"
                  target="_blank"
                  role="menuitem"
                  class="a-menu-item__link"
                  aria-controls="group-id contact"
                  rel="noopener"
                >
                  <span
                    elTestId="vgJspd8YAn5Ud3e5aQjkQ"
                    class="a-menu-item__label"
                  >
                    {{ 'redeem.title' | translate }}
                  </span>
                </a>
              </li>
              <li
                elTestId="jCLI16OBwD02h7PiuteAU"
                *ngIf="allowCreateEvaluationSandboxSubscription$ | async"
                class="a-menu-item"
                role="none"
                (click)="createEvaluationSubscription()"
              >
                <a
                  elTestId="G2jpv8WoxiEip1Tffe3h0"
                  role="menuitem"
                  class="a-menu-item__link"
                  aria-controls="group-id undefined"
                >
                  <span
                    elTestId="ofISWodGClQ4EKIdJlnyJ"
                    class="a-menu-item__label"
                    >{{ 'interface.createEvaluationSubscription' | translate }}
                  </span>
                  <em
                    elTestId="aai9W6E_qcNMHUv8qu7pf"
                    class="a-icon ui-ic-plus"
                    title="Create new subscription icon"
                  ></em>
                </a>
              </li>
            </ul>
          </li>
          <li
            elTestId="CVyRBeteVL8GIBY48fjsS"
            class="a-menu-item"
            role="none"
            (click)="hideMenu()"
          >
            <a
              elTestId="xfHVIr9thS4atdGjAF4iZ"
              (click)="authService.logout()"
              role="menuitem"
              class="a-menu-item__link"
              aria-disabled="false"
              aria-controls="group-id undefined"
            >
              <em
                elTestId="uLEBAjdZhp69Wqc4Cjdil"
                class="a-icon boschicon-bosch-ic-logout"
                title="Logout icon"
              ></em>
              <span
                elTestId="nUGre-lsD_ptYSU1m955n"
                class="a-menu-item__label"
                >{{ 'interface.logout' | translate }}</span
              >
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
