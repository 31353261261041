import { Component } from '@angular/core';
import { ChatbotService } from '../../../shared/services/chatbot.service';

@Component({
  selector: 'app-vertical-action-menu',
  standalone: true,
  imports: [],
  templateUrl: './vertical-action-menu.component.html',
  styleUrl: './vertical-action-menu.component.scss',
})
export class VerticalActionMenuComponent {
  constructor(private chatbotService: ChatbotService) {}

  clearChat() {
    this.chatbotService.clearChat();
  }
}
