import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatText',
  standalone: true,
})
export class FormatTextPipe implements PipeTransform {
  transform(value: string): string {
    // Use regular expressions to find text wrapped in `**` and replace with <strong> tags
    return value.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  }
}
