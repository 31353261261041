<ng-container
  *ngIf="logo === IdentityProvider.SingleKeyId; else boschLogoTemplate"
>
  <img
    elTestId="8B1NWXKo8e6E0QcY1DhPX"
    src="../../../assets/images/skid_logo_key_desc.svg"
    class="logo-style"
    title="Open"
    alt="Single Key Id Icon"
  />
</ng-container>
<ng-template #boschLogoTemplate>
  <img
    elTestId="8B1NWXKo8e6E0QcY1DhPX"
    src="../../../assets/images/bosch_logo.svg"
    title="Open"
    alt="Bosch Icon"
  />
</ng-template>
