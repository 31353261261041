<app-header [allowHeaderFunctions]="allowHeaderFunctions"></app-header>
<div
  elTestId="AWfW4z3m8YSzVOoxYAYed"
  class="error-container -full-width"
>
  <main
    elTestId="lGhz5Nd8dWEcchsWe48q5"
    class="content"
  >
    <div
      elTestId="Wi07EFLdL1rr-2ZOD1LJy"
      class="error-message-container"
      role="alert"
    >
      <em
        elTestId="H7DKzxuykTuQ5o4x7cLaG"
        class="a-icon ui-ic-alert-error"
        title="Subscription access"
      ></em>
      <div
        elTestId="oJr6PZxL-WKjV5AL5NMJD"
        id="notification-label-id-bar-error-error"
        class="a-notification__content error-message"
      >
        {{ message }}
      </div>
    </div>
  </main>
</div>
<public-footer></public-footer>
