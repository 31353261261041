<app-container-dialog
  header="{{ 'redeem.title' | translate }}"
  theme="info"
  [confirmButtonDisabled]="!code"
  [confirmButtonText]="'redeem.redeemButton' | translate"
  (confirmed)="redeem()"
  (cancelled)="close.emit()"
>
  <div
    elTestId="gsusZ30_nj02WHuA5RSRi"
    class="m-dialog__body"
    id="frontend-kit-dialog-alert-dialog-info-description"
  >
    <p elTestId="yqZQqd7rx_weEJTxytxZt">
      {{ 'redeem.input' | translate }}
    </p>
    <div
      elTestId="ks0aKepfjVYu2pe12Yy4R"
      class="m-form-field"
    >
      <div
        elTestId="nOIZ45TtfEZPrP_HGgdtJ"
        class="a-text-field"
      >
        <label
          elTestId="lZoTKpUcr8D-AJZq8swcx"
          for="3"
          >{{ 'redeem.code' | translate }}*</label
        >
        <input
          elTestId="6qkp-ufPBiLKYz4L4lrkM"
          type="text"
          [(ngModel)]="code"
          #redeemCode="ngModel"
          id="3"
        />
      </div>
    </div>
  </div>
</app-container-dialog>
