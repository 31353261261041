import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { tap } from 'rxjs';
import { ChatbotService } from '../../../shared/services/chatbot.service';
import { TypingEffectComponent } from '../typing-effect/typing-effect.component';
import { UserQueryInputComponent } from '../user-query-input/user-query-input.component';
import { VerticalActionMenuComponent } from "../vertical-action-menu/vertical-action-menu.component";
import { FormatTextPipe } from "../../../shared/pipes/format-text.pipe";

@Component({
  selector: 'app-chat-window',
  standalone: true,
  imports: [
    CommonModule,
    UserQueryInputComponent,
    TranslateModule,
    TypingEffectComponent,
    VerticalActionMenuComponent,
    FormatTextPipe,
  ],
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
})
export class ChatWindowComponent {
  @ViewChild('messagesContainer') messagesContainer!: ElementRef;
  @Output() closed = new EventEmitter<boolean>();

  readonly maxUserInput = 250;
  messages$ = this.chatbotService.messages.pipe(
    tap((messages) => {
      if (messages.length > 0) this.scrollToBottom();
    })
  );
  chatIntroText$ = this.chatbotService.chatIntroText;
  constructor(public chatbotService: ChatbotService) {}

  async onUserInput(userMessage: string) {
    if (!userMessage) {
      return;
    }

    this.chatbotService.sendUserMessage(userMessage);
  }

  scrollToBottom() {
    setTimeout(() => {
      this.messagesContainer.nativeElement.scroll({
        top: this.messagesContainer.nativeElement.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }, 200);
  }

  clearChat() {
    this.chatbotService.clearChat();
  }
}
