<div
  elTestId="FPS8ZZrUNHPZTl2W5lpbw"
  class="backdrop show"
>
  <div
    elTestId="XmJ0vJ-llWStsG2iUTK7f"
    id="frok-modal"
    class="m-dialog m-dialog--{{ themeClass }}"
    role="dialog"
    [attr.aria-labelledby]="header"
  >
    <div
      elTestId="x_2TL5dxlpdB-fQyq3Yt3"
      class="a-box -floating-shadow-s"
    >
      <div
        elTestId="CxGr8OPFc7vcgKStLZZ26"
        class="m-dialog__remark --{{ themeClass }}"
      ></div>
      <div
        elTestId="l-LrtYKdc3_-TdMfnpVMh"
        class="m-dialog__header"
      >
        <em
          elTestId="OaIDzINrHe1eDnUq3pRg-"
          class="a-icon ui-ic-alert-{{ themeClass }}"
          [title]="header"
        ></em>
        <div
          elTestId="BpCLhJZ5MBegAJhiLKmJ-"
          class="m-dialog__title"
        >
          {{ header }}
        </div>

        <button
          *ngIf="!hideCancelButton"
          elTestId="rV0I_v7Y9EI9-gwaQyVtM"
          type="button"
          class="a-button a-button--integrated -without-label"
          data-frok-action="close"
          aria-label="close dialog"
          (click)="cancelled.emit()"
        >
          <em
            elTestId="x2zveXEJN3I1pvTMZSkzD"
            class="a-icon a-button__icon ui-ic-close"
            title="Close"
          ></em>
        </button>
      </div>
      <hr
        elTestId="MOc7izgq1z-X37Tv61QhP"
        class="a-divider"
      />
      <div
        elTestId="eEkOp_VidUNLEQpsKWB6E"
        class="m-dialog__content"
      >
        <div
          *ngIf="subheader"
          elTestId="7V4PswZYSEQ-7ozqRy8el"
          class="m-dialog__headline"
        >
          {{ subheader }}
        </div>
        <div
          *ngIf="body"
          elTestId="_GNPGtavoUu9e-P5sa-FU"
          class="m-dialog__body"
        >
          <p elTestId="CKjCH9CDeOt4pANLKqEiR">
            {{ body }}
          </p>
        </div>

        <ng-content></ng-content>
        <div
          elTestId="QCTei3OpiUwsY7ukaa5vX"
          class="m-dialog__actions"
        >
          <button
            elTestId="hngaDoprda3HZyQhXSQ55"
            type="button"
            class="a-button a-button--primary -without-icon"
            data-frok-action="confirm"
            [disabled]="confirmButtonDisabled"
            (click)="confirmed.emit(true)"
          >
            <span
              elTestId="IP7A3RLuyiSNhmBZwGZz9"
              class="a-button__label"
              >{{ confirmButtonText || ('interface.save' | translate) }}</span
            >
          </button>
          <button
            *ngIf="!hideCancelButton"
            elTestId="VpGO7mQqkqGftAB4L3Ba_"
            type="button"
            class="a-button a-button--secondary -without-icon"
            data-frok-action="cancel"
            (click)="cancelled.emit(true)"
          >
            <span
              elTestId="yupDktfePmJMd6uftjuuS"
              class="a-button__label"
              >{{ 'interface.cancel' | translate }}</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
