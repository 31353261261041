<div class="vertical-menu">
  <button
    type="button"
    class="a-button a-button--tertiary -without-label"
    aria-label="accessibility label"
    [title]="'Start New Chat'"
    (click)="clearChat()"
  >
    <i class="a-icon a-button__icon  ui-ic-plus"></i>
  </button>
  <button
    type="button"
    class="a-button a-button--tertiary -without-label"
    aria-label="accessibility label"
    [title]="'View Chat History'"
  >
    <i class="a-icon a-button__icon boschicon-bosch-ic-history"></i>
  </button>
</div>