import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ChatbotService } from '../../shared/services/chatbot.service';
import { ChatWindowComponent } from './chat-window/chat-window.component';

@Component({
  selector: 'app-chatbot',
  standalone: true,
  imports: [CommonModule, ChatWindowComponent],
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
})
export class ChatbotComponent {
  chatWindowIsActive = false;

  constructor(private chatbotService: ChatbotService) {
    this.chatbotService.chatWindowCurrentVisibility.subscribe(
      (visible) => (this.chatWindowIsActive = visible)
    );
  }

  toggleChat() {
    this.chatbotService.setChatWindowVisibility(!this.chatWindowIsActive);
  }
}
